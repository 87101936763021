<template>
  <!--  <div class="white-space"></div> -->
  <div class="bottom">
    <div class="l">
      <li>Copyright© 2001-2024 NFSOC All Rights Reserved</li>
      <li>中国有色金属学会 版权所有 京ICP备15049308号-1</li>
      <li>地址:北京市海淀区复兴路乙12号</li>
      <li>邮编:100814 Email:nfsoc@163.com</li>
      <li>网站总浏览量：{{ counts }}</li>
    </div>
    <div class="r">
      <img src="../assets/img/wx.png" alt="">
    </div>
  </div>
</template>
<script>
import {scan,getDayViews} from '@/api/compoents'

export default {
  name: 'appBottom',
  data() {
    return {
      scans: 0,
      counts:0
    }
  },
  mounted() {
    this.getVisits()
  },
  methods: {
    /* 获取访问量 */
    async getVisits(){
      const {data:res} = await getDayViews()
      console.log(res);
      this.counts = res.count
    },
  }
}
</script>
<style scoped>


.bottom {
  --w: calc(var(--xd--fontsize) * 2);
  height: calc(var(--xd--fontsize) * 6);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  /* height:calc(var(--w) * 2); */
  /* padding: calc(var(--w) * .2); */
  /* min-width: 421px; */
  /* background: #192332; */
  background-color: #26425e;
  color: #fff;
  /*position: absolute;*/
  /* position: fixed; */
  /*margin-top: calc(var(--xd--fontsize) * 4);*/
  bottom: 0;
  left: 0;
  user-select: none;
  /* 使得文本内容不能被复制选中 */
}

.l {
  font-size: calc(var(--xd--fontsize) * .46);
}

.l li {
  line-height: calc(var(--w) * .5);
  margin-right: calc(var(--w) * .5);;
}

.r {
  /* margin-left: calc(var(--w) * .3); */
  width: calc(var(--w) * 1.69);
  height: calc(var(--w) * 1.69);
  overflow: hidden;
  margin-top: calc(var(--w) * .3);
  flex-shrink: 0;
}

.r img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* min-width: 82px; */
}
</style>
