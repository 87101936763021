<template>
    <div class="main">
      <headers></headers>
      <swiper></swiper>
      <module></module>
      <appA></appA>
     <appBottom class="bottom"></appBottom>
    </div>
</template>
<script>
// import { exportAllDeclaration } from "@babel/types";
import headers from '../home/components/header2'
import swiper from '../home/components/swiper.vue'
import module from './components/module.vue'
import appA from './components/platform.vue'
import appBottom from '../../components/bottom.vue'

export default {
  name: 'appHome',
  components: {
    headers,
    swiper,
    module,
    appA,
    appBottom
  }
}
</script>
<style scoped>
  .main{
    --h: calc(var(--xd--fontsize) * 2);
    animation: show .5s;
    width: 100vw;
    /*height: 230vh;*/
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    /*用于控制轮播图距离顶部多高*/
    top:calc(var(--h)*.2);
  }
  @media screen and (max-width: 790px) {
    .main {
      /*height: 162vh;*/
      top:calc(var(--h)*.2);
    }
    .bottom {
      /*position: absolute;*/
      bottom: 0;
    }
  }
  @keyframes show {
    0%{
      opacity: 0;
    }
  }
</style>
