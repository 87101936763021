<template>
  <div class="container">
    <div class="arrow left" @click="prevSlide">
      <img src="../../../assets/img/左.png" alt="" />
    </div>
    <div class="carousel" ref="changeDistance" @mouseenter="stopAutoPlay"  @mouseleave="autoPlay">
      <div
        class="slide"
        ref="slideRef"
        @click="jumpOtherWeb(item)"
        v-for="(item, index) in data.imgInfo"
        :key="item.pictureId + index"
      >
        <div class="slideImg">
          <img :src="baseUrl + item.pictureUrl" />
        </div>
      </div>
      <div class="slide">
        <div class="slideImg">
          <img :src="baseUrl + firstPicUrl" />
        </div>
      </div>
    </div>
    <div class="arrow right" @click="nextSlide()">
      <img src="../../../assets/img/更多.png" alt="" />
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, nextTick, onUpdated, onUnmounted } from "vue";
import { getHomePic } from "@/api/compoents";
import router from "@/router";
export default {
  name: "smallSwiper",
  setup() {
    //const baseUrl = 'http://39.103.190.34:8181'
    const baseUrl = 'http://59.110.106.225:8181'
    const data = ref({
      imgInfo: [],
    });
    const slideRef = ref();
    const changeDistance = ref();
    let picWidth = ref(0);
    let picMarginRight = ref(0);
    let currentWidth = ref(0);
    let autoplayInterval = ref(null);

    let firstPicUrl = ref();
    let realPic = ref(0);
    /* 获取图片数据 */
    async function getHomePicture() {
      const { data: res } = await getHomePic();
      console.log(res);
      data.value.imgInfo = res.middlePictures;
      if (data.value.imgInfo.length === 1) {
        realPic.value = 1;
        let imageClone = { ...data.value.imgInfo[0] };
        for (let i = 0; i < 6; i++) {
          data.value.imgInfo.push(imageClone);
        }
      } else if (data.value.imgInfo.length === 2) {
        realPic.value = 2;
        let firstClone = { ...data.value.imgInfo[0] };
        let secondClone = { ...data.value.imgInfo[1] };
        data.value.imgInfo.push(firstClone);
        data.value.imgInfo.push(secondClone);
        data.value.imgInfo.push(firstClone);
        data.value.imgInfo.push(secondClone);
      } else if (data.value.imgInfo.length === 3) {
        realPic.value = 3;
        let firstClone = { ...data.value.imgInfo[0] };
        let secondClone = { ...data.value.imgInfo[1] };
        let thirdClone = { ...data.value.imgInfo[2] };
        data.value.imgInfo.push(firstClone);
        data.value.imgInfo.push(secondClone);
        data.value.imgInfo.push(thirdClone);
      } else if(data.value.imgInfo.length === 4){
        realPic.value = 4
        data.value.imgInfo.push(...data.value.imgInfo);
      }
      firstPicUrl.value = data.value.imgInfo[0].pictureUrl;
      realPic.value = data.value.imgInfo.length
      data.value.imgInfo.push(...data.value.imgInfo);
    }
    //获取图片宽度
    function getSlideWidth() {
      picWidth.value = slideRef.value[0].offsetWidth;
      
      let box = window.getComputedStyle(slideRef.value[0]);
      picMarginRight.value = parseFloat(box.marginRight);
      currentWidth.value = picWidth.value + picMarginRight.value;
    }
    //整体左移
    function prevSlide() {
      getSlideWidth();
      moveSlide(currentWidth.value);
    }
    //整体右移
    function nextSlide() {
      getSlideWidth();
      moveSlide(-currentWidth.value);
    }
    const distances = ref(0);
    const moveSlide = (distance) => {
      distances.value += distance;
      if (realPic.value === 1 || realPic.value === 3) {
        if (-distances.value >= currentWidth.value * 4) {
          /* 右移重置 */
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          distances.value = 0;
          changeDistance.value.style.transition = "none";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          nextSlide();
        } else if (-distances.value < 0) {
          /* 左移重置 */
          distances.value = -distance * 3;
          changeDistance.value.style.transition = "none";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          prevSlide();
        } else {
          /* 正常移动 */
          changeDistance.value.style.transition = "transform 0.5s ease-in-out";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
        }
      }else if(realPic.value === 2){
        if (-distances.value >= currentWidth.value * 3) {
          /* 右移重置 */
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          distances.value = 0;
          changeDistance.value.style.transition = "none";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          nextSlide();
        } else if (-distances.value < 0) {
          /* 左移重置 */
          distances.value = -distance * 2;
          changeDistance.value.style.transition = "none";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          prevSlide();
        } else {
          /* 正常移动 */
          changeDistance.value.style.transition = "transform 0.5s ease-in-out";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          console.log(changeDistance.value);
        }
      
      }else if(realPic.value === 4){
        if (-distances.value >= currentWidth.value * 5) {
          /* 右移重置 */
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          distances.value = 0;
          changeDistance.value.style.transition = "none";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          nextSlide();
        } else if (-distances.value < 0) {
          /* 左移重置 */
          distances.value = -distance * 4;
          changeDistance.value.style.transition = "none";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          prevSlide();
        } else {
          /* 正常移动 */
          changeDistance.value.style.transition = "transform 0.5s ease-in-out";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          console.log(changeDistance.value);
        }
      
      }else if(realPic.value >= 5){
        if (-distances.value >= currentWidth.value * realPic.value+1) {
          /* 右移重置 */
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          distances.value = 0;
          changeDistance.value.style.transition = "none";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          nextSlide();
        } else if (-distances.value < 0) {
          /* 左移重置 */
          distances.value = -distance * realPic.value;
          changeDistance.value.style.transition = "none";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
          prevSlide();
        } else {
          /* 正常移动 */
          changeDistance.value.style.transition = "transform 0.5s ease-in-out";
          changeDistance.value.style.transform = `translateX(${distances.value}px)`;
        }
      }
    };
    /* 自动播放 */
    let autoPlay = function autoPlay(){
      stopAutoPlay();
      autoplayInterval.value = setInterval(() => {
        nextSlide();
      }, 4000);
    }
    function stopAutoPlay() {
      if (autoplayInterval.value) {
        clearInterval(autoplayInterval.value);
      }
    }
    /* 跳转链接地址 */
    function jumpOtherWeb(e){
      if(e.link !== null){
        window.location.href = e.link
      }
      console.log(e.link);
      
    }
    onMounted(() => {
      getHomePicture();
      autoPlay()
      console.log(process.env.VUE_APP_BASE_API);
    });
    onUnmounted(()=>{
      stopAutoPlay();
    })
    return {
      data,
      slideRef,
      prevSlide,
      getSlideWidth,
      nextSlide,
      changeDistance,
      distances,
      firstPicUrl,
      autoPlay,
      autoplayInterval,
      stopAutoPlay,
      baseUrl,
      jumpOtherWeb
    };
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .slide{
    width: 100% !important;
  }
}
.container {
  position: relative;
  width: 93%;
  height: 100px;
  margin: 0 auto;
  margin-top: 10px;
  overflow: hidden;
}

.carousel {
  display: flex;
  position: relative;
  transition: transform 0.5s ease-in-out;
}
.centered {
  justify-content: center; /* 图片数量小于或等于4时居中 */
}

.slide {
  flex-shrink: 0;
  display: flex;
  width: 24.25%;
  height: 100px;
  margin-right: 1%;
}
.slide:last-child {
  margin-right: 0px;
}

.slideImg {
  flex: 1;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.slideImg img {
  width: 100%;
  height: 100%;
}
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  color: #333;
}

.left {
  left: 0px;
  width: 40px;
  height: 60px;
  background-color: rgba(42, 42, 42, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left img {
  position: relative;
  width: 50%;
  height: 50%;
}

.right {
  right: 0px;
  width: 40px;
  height: 60px;
  background-color: rgba(42, 42, 42, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.right img {
  position: relative;
  width: 50%;
  height: 50%;
}
</style>
