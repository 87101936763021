<template>
  <div class="y-com-logobox" y-com-logo y-flexX>
    <div class="y-com-logobox-main" y-flexX>
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YcomLogo'
}
</script>

<style scoped>

.y-com-logobox-main {
  width: 80%;
  height: 80%;
  position: relative;
  z-index: 1;
  /* background-color: #fff; */
  /* overflow: hidden; */
  border-radius: 10vw;
}
</style>
